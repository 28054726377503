import { useLazyQuery } from "@apollo/client";
import { clsMerge } from "@artifactlabs/shared-react-utils";
import { faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Combobox } from "@headlessui/react";
import _isEmpty from "lodash/isEmpty";
import Image from "next/image";
import { useRouter, useSearchParams } from "next/navigation";
import { KeyboardEvent, useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useDebounce } from "usehooks-ts";

import { getArtworkAutocompleteResultsQuery } from "@/api/queries/search";
import {
  GetArtworkAutocompleteResultsQuery,
  GetArtworkAutocompleteResultsQueryVariables,
} from "@/gql/graphql";
import { useCustomTranslation } from "@/hooks/useCustomTranslation";
import { useWindowResize } from "@/hooks/useWindowResize";
import autocompleteAtom from "@/recoil/autocomplete/atom";
import tenantAtom from "@/recoil/tenant/atom";
import { routes } from "@/utils/routes";
import { zSearchBarOptionItem, zSearchBar } from "@/utils/zIndex";

const STORAGE_URL = process.env.NEXT_PUBLIC_S3_PUBLIC_ASSETS_BUCKET_HOST_NAME!;

interface Props {
  className?: string;
  currentState?: (isOpen: boolean) => void;
}

const ArtworkSearchAutocomplete = (props: Props) => {
  //
  const router = useRouter();

  const searchParams = useSearchParams();
  const [autocomplete, setAutocomplete] = useRecoilState(autocompleteAtom);

  const [isCreator, setIsCreator] = useState(false);

  //
  const [creatorOptions, setCreatorOptions] = useState<
    GetArtworkAutocompleteResultsQuery["getArtworkAutocompleteResults"]
  >([]);
  const [assetOptions, setAssetOptions] = useState<
    GetArtworkAutocompleteResultsQuery["getArtworkAutocompleteResults"]
  >([]);

  const debouncedInputQuery = useDebounce<string>(autocomplete.searchQuery, 250);

  //
  const { t } = useCustomTranslation("home");
  const [isOpen, setIsOpen] = useState(false);
  const [tenant] = useRecoilState(tenantAtom);
  const isMobile = useWindowResize();

  //
  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);

  const [fetchAutocompleteResult, { data: autocompleteResults }] = useLazyQuery<
    GetArtworkAutocompleteResultsQuery,
    GetArtworkAutocompleteResultsQueryVariables
  >(getArtworkAutocompleteResultsQuery);

  /**
   * Given we're updating everything through the atom, query the autocomplete in an useEffect
   */
  useEffect(() => {
    if (debouncedInputQuery) {
      fetchAutocompleteResult({ variables: { query: debouncedInputQuery } });
    }
  }, [debouncedInputQuery, fetchAutocompleteResult]);

  /*
    this hook is responsible for binding the UI to options instead of directly read from data.
    by doing this, we can ensure that the UI won't go blank when querying by other keywords
  */
  useEffect(() => {
    if (autocompleteResults) {
      const creators: GetArtworkAutocompleteResultsQuery["getArtworkAutocompleteResults"] = [];
      const creatorsObject: { [key: string]: boolean } = {};
      const assets: GetArtworkAutocompleteResultsQuery["getArtworkAutocompleteResults"] = [];
      autocompleteResults?.getArtworkAutocompleteResults?.forEach(artwork => {
        if (artwork === null) return;

        switch (artwork?.matchedField?.path) {
          case "metadata.creatorName": {
            const creatorName = artwork.metadata?.creatorName ?? "";
            if (!creatorsObject[creatorName]) {
              creatorsObject[creatorName] = true;
              // get creator's avatar
              creators.push({
                ...artwork,
                src: `https://${STORAGE_URL}/artists/${creatorName}_profile.png`,
              });
            }
            break;
          }
          case "metadata.name":
          default: {
            assets.push(artwork);
            break;
          }
        }
      });

      setCreatorOptions(creators);
      setAssetOptions(assets);
    }
  }, [autocompleteResults]);

  // this hook is responsible for updating the autocomplete atom based on the query params
  useEffect(() => {
    setAutocomplete(prev => ({
      ...prev,
      searchQuery: searchParams?.get("keyword") ?? "",
    }));
  }, [searchParams, setAutocomplete]);

  useEffect(() => {
    props.currentState?.(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  /* ********************************************************************** */
  // Event Handlers
  /* ********************************************************************** */

  // Handle search query change
  const handleOnSearchQueryChange = (newQuery: string) => {
    setAutocomplete({
      ...autocomplete,
      searchQuery: newQuery ?? "",
    });
  };

  // Handle click on autocomplete item
  const handleClickAutocomplete =
    (search: string, type: string = "asset") =>
    () => {
      if (type === "asset") {
        const params = new URLSearchParams();
        params.set("keyword", search);
        router.push(routes.search(/*page*/ 1, "image", params, /*withDefaultParams*/ true));
      } else {
        router.push(`/artist/${search}`);
      }
    };

  const executeSearch = useCallback(() => {
    const params = new URLSearchParams();
    params.set("keyword", autocomplete.searchQuery);
    // DEV-2282
    if (_isEmpty(autocomplete.searchQuery)) {
      params.set("sort", "1"); // if there's no specific keyword, sort the result by most recent
    } else {
      params.set("sort", "0"); // if there's a keyword, sort the result by "best match"
    }

    if (isCreator) {
      router.push(`/artist/${autocomplete.searchQuery}`);
    } else {
      // withDefaultParams=false because we explicitly set the sort above
      router.push(routes.search(/*page*/ 1, "image", params, /*withDefaultParams*/ false));
    }
  }, [autocomplete.searchQuery, router, isCreator]);

  // Handle Enter key press
  const handlePressEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key.toLowerCase() === "enter") {
      executeSearch();
    }
  };

  /* ********************************************************************** */
  // Renderers
  /* ********************************************************************** */

  //
  const renderSearchBar = () => {
    //
    switch (tenant?.id) {
      case "scmp": {
        return (
          <div
            className={clsMerge(
              "h-[48px] w-full",
              "flex items-center gap-2",
              "rounded-[4px] md:rounded-[8px]",
              "px-[16px] py-[12px]",
              "border border-[#BBBBBB] ring-0 md:border-[#001246]",
              "--font-roboto text-[14px] font-[300] leading-[16.8px]",
              isSearchBarFocused
                ? "border-[#222834] text-[#222834]"
                : "text-[#BBBBBB] md:text-[#222834]",
            )}
          >
            {/* Search Icon */}
            <Combobox.Button className="" onClick={() => handleOnSearchQueryChange("")}>
              <FontAwesomeIcon className="" height={20} icon={faSearch} width={20} />
            </Combobox.Button>

            {/* Search Input */}
            <Combobox.Input
              aria-expanded={false}
              className={clsMerge(
                "h-full w-full",
                "placeholder-[#BBBBBB] md:placeholder-[#222834]",
                "border-0 ring-0 focus:outline-none focus:ring-0",
              )}
              displayValue={() => autocomplete.searchQuery}
              placeholder={t("SEARCH_BAR.PLACEHOLDER")}
              onBlur={() => {
                document.documentElement.style.zoom = "1.0";
                setIsSearchBarFocused(false);
              }}
              onChange={event => handleOnSearchQueryChange(event.target.value)}
              onFocus={() => {
                setIsSearchBarFocused(true);
              }}
              onKeyUp={handlePressEnter}
            />
          </div>
        );
      }
      default: {
        return (
          <div
            className={clsMerge(
              "flex h-full gap-2",
              "rounded-[8px] bg-white",
              "px-[15px] md:px-[16px]",
            )}
          >
            {/* Search Icon */}
            <Combobox.Button className="text-[#B4B4B4]" onClick={executeSearch}>
              <FontAwesomeIcon className="" height={20} icon={faSearch} width={20} />
            </Combobox.Button>

            {/* Search Input */}
            <Combobox.Input
              aria-expanded={false}
              className={clsMerge(
                "w-full border-0 ring-0 focus:outline-none focus:ring-0",
                "text-base leading-6 text-gray-900",
                tenant.id === "platform" && "placeholder-tenant-gray-light",
              )}
              displayValue={() => autocomplete.searchQuery}
              placeholder={t("SEARCH_BAR.PLACEHOLDER")}
              onBlur={() => {
                document.documentElement.style.zoom = "1.0";
              }}
              onChange={event => handleOnSearchQueryChange(event.target.value)}
              onKeyUp={handlePressEnter}
            />

            {/* Clear Search Button */}
            {autocomplete.searchQuery && (
              <Combobox.Button
                className="items-center text-[#B4B4B4]"
                onClick={() => handleOnSearchQueryChange("")}
              >
                <FontAwesomeIcon className="" height={20} icon={faXmark} width={20} />
              </Combobox.Button>
            )}
          </div>
        );
      }
    }
  };

  //
  const renderSearchResultBox = () => {
    switch (tenant?.id) {
      case "platform": {
        return (
          [...(creatorOptions ?? []), ...(assetOptions ?? [])].length > 0 && (
            <Combobox.Options
              className={clsMerge(
                "absolute flex max-h-80 w-full flex-col overflow-auto pb-[12px]",
                "bg-white",
                "rounded-b-[4px] ring-1 ring-[#EDECE5] md:rounded-b-[8px]",
                "--font-roboto text-[14px] font-[300] leading-[16.8px] text-[#222834]",
                zSearchBarOptionItem.className,
              )}
            >
              <Combobox.Option
                key={"option.user.input"}
                value={autocomplete.searchQuery}
                onKeyUp={handleClickAutocomplete(autocomplete.searchQuery)}
              >
                {({ active }) => (
                  <div
                    className={`w-full py-5 pl-8 pr-8 text-left ${
                      active ? "bg-tenant-blue-light text-black" : "bg-white text-black"
                    }`}
                    onClick={handleClickAutocomplete(autocomplete.searchQuery)}
                  >
                    {autocomplete.searchQuery}
                  </div>
                )}
              </Combobox.Option>

              {Boolean(creatorOptions?.length) && [
                renderSearchResultBoxSectionHeader("Creators"),
                creatorOptions!.map((creator: any) => renderSearchResultBoxRow(creator, "creator")),
              ]}

              {Boolean(assetOptions?.length) && [
                renderSearchResultBoxSectionHeader("Assets"),
                assetOptions!.map(artwork => renderSearchResultBoxRow(artwork, "asset")),
              ]}
            </Combobox.Options>
          )
        );
      }
      case "scmp": {
        return (
          <Combobox.Options
            className={clsMerge(
              "absolute mt-[4px] flex max-h-80 w-full flex-col overflow-auto",
              "rounded-[4px] ring-1 ring-[#001246] md:rounded-[8px]",
              "--font-roboto text-[14px] font-[300] leading-[16.8px] text-[#222834]",
              zSearchBarOptionItem.className,
            )}
          >
            <Combobox.Option
              key={"option.user.input"}
              value={autocomplete.searchQuery}
              onKeyUp={handleClickAutocomplete(autocomplete.searchQuery)}
            >
              {({ active }) => (
                <div
                  className={`w-full py-5 pl-[54px] pr-8 text-left ${
                    active && !isMobile ? "bg-tenant-blue-light text-black" : "bg-white text-black"
                  }`}
                  onClick={handleClickAutocomplete(autocomplete.searchQuery)}
                >
                  {autocomplete.searchQuery}
                </div>
              )}
            </Combobox.Option>

            {[...(creatorOptions ?? []), ...(assetOptions ?? [])].map((artwork: any) => (
              <Combobox.Option
                key={artwork.extId}
                value={artwork.metadata.name}
                onKeyUp={handleClickAutocomplete(artwork.metadata.name)}
              >
                {({ active }) => (
                  <div
                    className={`w-full py-5 pl-[54px] pr-8 text-left ${
                      active && !isMobile
                        ? "bg-tenant-blue-light text-black"
                        : "bg-white text-black"
                    }`}
                    onClick={handleClickAutocomplete(artwork.metadata.name)}
                  >
                    {artwork.metadata.name}
                  </div>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        );
      }
      default: {
        return (
          [...(creatorOptions ?? []), ...(assetOptions ?? [])].length > 0 && (
            <Combobox.Options
              className={clsMerge(
                "absolute flex max-h-80 w-full flex-col overflow-auto pb-[12px]",
                "bg-white",
                "rounded-b-[4px] ring-1 ring-[#001246] md:rounded-b-[8px]",
                "--font-roboto text-[14px] font-[300] leading-[16.8px] text-[#222834]",
                zSearchBarOptionItem.className,
              )}
            >
              <Combobox.Option
                key={"option.user.input"}
                value={autocomplete.searchQuery}
                onKeyUp={handleClickAutocomplete(autocomplete.searchQuery)}
              >
                {({ active }) => (
                  <div
                    className={`w-full py-5 pl-8 pr-8 text-left ${
                      active ? "bg-tenant-blue-light text-black" : "bg-white text-black"
                    }`}
                    onClick={handleClickAutocomplete(autocomplete.searchQuery)}
                  >
                    {autocomplete.searchQuery}
                  </div>
                )}
              </Combobox.Option>

              {(assetOptions ?? []).length > 0 && renderSearchResultBoxSectionHeader("Assets")}

              {[...(creatorOptions ?? []), ...(assetOptions ?? [])].map((artwork: any) => {
                return renderSearchResultBoxRow(artwork, "asset");
              })}
            </Combobox.Options>
          )
        );
      }
    }
  };

  //
  const renderSearchResultBoxSectionHeader = (title: string) => {
    return (
      <div
        className={clsMerge(
          "w-full bg-white",
          "px-[16px] pt-[11px] md:pt-[15px]",
          "tenant-title-2 font-[600] text-[--tenant-gray-light]",
        )}
      >
        {title}
      </div>
    );
  };

  //
  const renderSearchResultBoxRow = (artwork: any, type: string = "asset") => {
    const keyword = type === "asset" ? artwork.metadata.name : artwork.metadata.creatorName;
    return (
      <Combobox.Option
        key={artwork.extId}
        value={keyword}
        onKeyUp={handleClickAutocomplete(keyword, type)}
      >
        {({ active }) => {
          if (active) {
            setIsCreator(type === "creator");
          }
          return (
            <div
              className={clsMerge(
                "flex w-full items-center justify-start bg-white",
                "h-[47px] md:h-[61px]",
                "py-[4px] md:py-[8px]",
                active ? "bg-tenant-blue-light text-black" : "bg-white text-black",
              )}
              onClick={handleClickAutocomplete(keyword, type)}
            >
              {/* Preview Icon */}
              <div className={clsMerge("mx-[24px] my-[16px] flex h-[32px] w-[32px]")}>
                <Image
                  alt={keyword}
                  className={clsMerge(
                    "object-contain",
                    type === "asset" ? "rounded-[0px]" : "rounded-[0px]",
                  )}
                  height={36}
                  src={artwork.src}
                  width={36}
                />
              </div>

              {/* Title */}
              <div
                className={clsMerge(
                  "tenant-title-1 flex flex-col justify-center font-[600] text-[--tenant-dark-gray]",
                  "w-full overflow-x-hidden",
                  "pr-[16px] md:pr-[15px]",
                )}
              >
                <div className={clsMerge("flex-shrink-0 truncate")}>{keyword}</div>
                <div
                  className={clsMerge(
                    "truncate",
                    "tenant-body-1 font-[400] text-[--tenant-gray-light]",
                    type === "asset" ? "flex items-start" : "hidden",
                  )}
                >
                  {artwork.metadata.creatorName ?? "-"}
                </div>
              </div>
            </div>
          );
        }}
      </Combobox.Option>
    );
  };

  //
  return (
    <div className={clsMerge(props.className)}>
      <Combobox value={autocomplete.searchQuery} onChange={handleOnSearchQueryChange}>
        {({ open }) => {
          setIsOpen(open);
          switch (tenant.id) {
            case "scmp": {
              return (
                <div className={clsMerge("item-center flex flex-col justify-center")}>
                  {/* Search Menu*/}
                  <div
                    className={clsMerge(
                      "flex h-[64px] w-full items-center justify-start",
                      zSearchBar.className,
                    )}
                  >
                    {/* SearchBar */}
                    <div
                      className={clsMerge(
                        "relative",
                        "flex-grow rounded-[8px] bg-white",
                        zSearchBar.className,
                      )}
                    >
                      {renderSearchBar()}

                      {/* Search Result Box */}
                      {autocomplete.searchQuery && open && (
                        <div className="absolute left-0 top-full z-10 w-full pt-[2px]">
                          {renderSearchResultBox()}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
            default: {
              return (
                <div
                  className={clsMerge(
                    "flex h-[48px] items-center justify-start",
                    "rounded-[8px]",
                    zSearchBar.className,
                  )}
                >
                  {/* SearchBar */}
                  <div className={clsMerge("relative h-full flex-grow", zSearchBar.className)}>
                    {renderSearchBar()}

                    {/* Search Result Box */}
                    {autocomplete.searchQuery && open && (
                      // top-[90%] is used to make the search result box appear below and overlapping the search bar
                      <div className={clsMerge("absolute left-0 w-full", "top-[90%]")}>
                        {renderSearchResultBox()}
                      </div>
                    )}
                  </div>
                </div>
              );
            }
          }
        }}
      </Combobox>
    </div>
  );
};

export default ArtworkSearchAutocomplete;
