import { clsMerge } from "@artifactlabs/shared-react-utils";
import { FunctionComponent } from "react";

import ImageItem from "./ImageItem";

type FeaturedCreatorProps = {
  artworkCount: number;
  className?: string;
  imageUrl: string;
  name: string;
};

const FeaturedCreator: FunctionComponent<FeaturedCreatorProps> = ({
  name,
  artworkCount,
  imageUrl,
  className,
}) => {
  return (
    <div className={clsMerge("border-#EDECE5 rounded-[8px] border-[1px]", className)}>
      <ImageItem alt={name} imageSrc={imageUrl} navigateTo={`/artist/${name}`} />
      <div className="mx-6 my-4 flex w-full flex-col">
        <div className="text-base font-semibold text-[#2B2C30]">{name}</div>
        <div className="text-sm font-normal text-[#87888C]">{artworkCount} assets</div>
      </div>
    </div>
  );
};

export default FeaturedCreator;
