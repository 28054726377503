import clsx from "clsx";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import useLazyLoadVideo from "@/hooks/useLazyLoadVideo";

interface props {
  className?: string;
  extId: string;
  src: string;
  assetSrc: string;

  duration?: string;

  showControls?: boolean;
  showDuration?: boolean;
  playOnMouseHover?: boolean;
  loop?: boolean;
  muted?: boolean;
  title?: string;
  creator?: string;
}

//
const formatDuration = (duration: number) => {
  const date = new Date(duration * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  } else {
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }
};

//
const VideoPreviewer = (data: props) => {
  //
  const { videoRef, isLoaded } = useLazyLoadVideo();
  const [duration, setDuration] = useState<number | null>(null);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [showTitle, setShowTitle] = useState(false);

  //
  useEffect(() => {
    const videoElement: HTMLVideoElement | any = videoRef.current;
    //
    if (videoElement) {
      videoElement.controlsList = "nodownload noremoteplayback";

      //
      const handleLoadedMetadata = () => {
        setDuration(videoElement.duration);
      };

      const handleCanPlay = () => {
        setIsVideoReady(true);
      };

      videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);
      videoElement.addEventListener("canplay", handleCanPlay);

      //
      return () => {
        videoElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
        videoElement.removeEventListener("canplay", handleCanPlay);
      };
    }
  }, [videoRef]);

  /* **************************************************** */
  // Event Handlers
  /* **************************************************** */

  const handleMouseOver = () => {
    if (videoRef.current && data.playOnMouseHover) {
      videoRef.current.play();
      setShowTitle(true);
    }
  };

  const handleMouseOut = () => {
    if (videoRef.current && data.playOnMouseHover) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0; // Reset video to start
      setShowTitle(false);
    }
  };

  //
  const handleVideoClick = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (videoElement.paused) {
        videoElement.play();
      } else {
        videoElement.pause();
      }
    }
  };

  //
  const handleVideoDoubleClick = () => {
    const videoElement = videoRef.current;
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    } else {
      if (videoElement) {
        if (videoElement.requestFullscreen) {
          videoElement.requestFullscreen();
        }
      }
    }
  };

  /* **************************************************** */
  // Helpers
  /* **************************************************** */

  /* **************************************************** */
  // Renderers
  /* **************************************************** */

  return (
    <>
      {isMobile && data.showDuration ? (
        <Image
          alt=""
          className={clsx("object-contain", data.className)}
          height={100}
          quality={1}
          sizes="100vw"
          src={data.src}
          width={100}
          unoptimized
        />
      ) : (
        <div className="relative">
          {showTitle && (
            <div
              className="absolute left-0 top-0 flex h-full w-full items-end rounded-[8px] p-[16px]"
              style={{
                background: "rgba(0, 0, 0, 0.3)",
              }}
            >
              <div className="px-auto flex w-full flex-col">
                <div className="line-clamp-2 overflow-hidden text-ellipsis text-[14px] font-[600] text-white">
                  {data.title}
                </div>
                <div className="line-clamp-1 overflow-hidden text-ellipsis text-[12px] font-[400] text-white">
                  {data.creator}
                </div>
              </div>
            </div>
          )}
          <video
            className={clsx("object-contain", data.className)}
            controls={data.showControls}
            height={"100%"}
            key={data.extId}
            loop={data.loop}
            muted={data.muted}
            ref={videoRef}
            src={isLoaded ? data.assetSrc : undefined}
            style={{ visibility: isVideoReady ? "visible" : "hidden" }}
            width={"100%"}
            onBlur={() => handleMouseOut()}
            onClick={e => {
              if (data.showControls) {
                e.preventDefault();
              }
            }}
            onDoubleClick={e => {
              if (data.showControls) {
                e.preventDefault();
              }
            }}
            onFocus={() => handleMouseOver()}
            onMouseOut={() => handleMouseOut()}
            onMouseOver={() => handleMouseOver()}
            onTouchStart={e => {
              e.preventDefault();
            }}
          >
            <track kind="captions" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}

      {data.duration !== null && data.showDuration && (
        <div
          className="
            md:px-70
            absolute bottom-4 right-4
            flex h-[26px] w-[45px] items-center
            justify-center rounded-[8px] bg-black
            bg-opacity-60 px-1 py-1 backdrop-blur-[12px]
            md:bottom-[8px] md:right-[8px]
            md:h-[29px] md:w-[48px] md:py-4
          "
        >
          <div
            className="
              line-height-[18px]
              md:line-height-[21px] text-[12px]
              font-[400]
              text-white md:text-[14px]
            "
          >
            {formatDuration(parseFloat(data.duration ?? "0"))}
          </div>
        </div>
      )}
    </>
  );
};

export default VideoPreviewer;
