import React, { FunctionComponent, HTMLAttributes, useEffect, useMemo, useState } from "react";
import { RowsPhotoAlbum, RenderImageProps, RenderImageContext } from "react-photo-album";
import "react-photo-album/rows.css";

import AppLink from "@/components/AppLink";
import GalleryImage from "@/components/Image/GalleryImage";
import Spinner from "@/components/Spinner";
import VideoPreviewer from "@/components/VideoPreviewer";
import { SearchArtworksQuery } from "@/gql/graphql";
import { useWindowResize } from "@/hooks/useWindowResize";
import { routes } from "@/utils/routes";

interface Props extends HTMLAttributes<HTMLDivElement> {
  loading: boolean;
  assets: SearchArtworksQuery["searchArtworks"];
}

//
enum AssetType {
  Video = "video",
  Image = "image",
  Audio = "audio",
}

//
interface AssetProps {
  src: string;
  width: number;
  height: number;
  title: string;
  alt: string;
  assetType: AssetType;
  assetSrc: string;
  assetDuration: string;
  extId: string;
  creator: string;
}

//
//
function renderNextImage(
  { alt = "", title, sizes }: RenderImageProps,
  { photo, width, height }: RenderImageContext,
  { src, assetType, assetSrc, assetDuration, extId, creator }: AssetProps,
  isSingle = false,
  isMobile = false,
) {
  const aspectRatio = width / height;
  //
  return (
    <div
      style={{
        width: "100%",
        maxWidth: isSingle && !isMobile ? 280 * aspectRatio : "",
        position: "relative",
        aspectRatio: aspectRatio,
      }}
    >
      <AppLink href={routes.artwork(extId)}>
        {assetType === "video" ? (
          <React.Suspense fallback={<></>}>
            <VideoPreviewer
              assetSrc={assetSrc}
              className="h-full w-full rounded-[8px]"
              creator={creator}
              duration={assetDuration}
              extId={extId}
              loop={true}
              muted={true}
              playOnMouseHover={true}
              showControls={false}
              showDuration={true}
              src={src}
              title={title}
            />
          </React.Suspense>
        ) : (
          <GalleryImage
            alt={alt}
            creator={creator}
            sizes={sizes}
            src={photo as unknown as string}
            title={title}
            fill
            unoptimized
          />
        )}
      </AppLink>
    </div>
  );
}

export const MasonryGallery: FunctionComponent<Props> = ({
  assets,
  loading,
  children,
  ...rest
}) => {
  //
  const [images, setImages] = useState<AssetProps[]>([]);

  //
  const isMobile = useWindowResize();
  const ITEM_HEIGHT = isMobile ? 220 : 360;

  //
  const imagePromises = useMemo(() => {
    return (assets?.paginatedResults ?? []).map(item => {
      const assetType = item?.metadata?.assetFileFormat?.split("/")[0];

      const img = new window.Image();
      img.src = item?.src ?? "";

      return new Promise(resolve => {
        img.onload = () => {
          resolve({
            src: img.src,
            width: img.naturalWidth,
            height: img.naturalHeight,
            title: item?.metadata?.name ?? "",
            creator: item?.metadata?.creatorName ?? "-",
            alt: item?.metadata?.name ?? "",
            assetType: assetType,
            assetSrc: item?.assetSrc,
            assetDuration: item?.assetDuration,
            extId: item?.extId,
          });
        };
      });
    });
  }, [assets]);

  //
  useEffect(() => {
    (async () => {
      const loadedImages = await Promise.all(imagePromises);
      setImages(loadedImages as any);
    })();
  }, [imagePromises]);
  //
  return (
    <div {...rest}>
      {loading ? (
        <div className="flex flex-1 items-center justify-center py-6">
          <Spinner />
        </div>
      ) : (
        <>
          {(assets?.paginatedResults ?? []).length > 0 && (
            <>
              <RowsPhotoAlbum
                photos={images}
                render={{
                  image: (props, context) => {
                    const image = images.find(img => img.src === context.photo.src);
                    return renderNextImage(
                      props,
                      context,
                      image as any,
                      images.length === 1,
                      isMobile,
                    );
                  },
                }}
                spacing={isMobile ? 16 : 24}
                targetRowHeight={ITEM_HEIGHT}
              />

              {children}
            </>
          )}

          {(assets?.paginatedResults ?? []).length === 0 && (
            <div className="flex items-center justify-center pb-[40px] pt-12">
              <div className=" text-sm">No results found</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
