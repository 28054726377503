import { ReadonlyURLSearchParams } from "next/navigation";

import { getLocalizationProvider, mapPlatformCopyrightToIssuer } from "@/utils/tenant";

export type ParsedSearchResultRouterQuery = {
  keyword: string;
  sort: string;
  slug: Array<string>;
  relevantDatesFacet?: string;
  creatorNameFacet?: string;
  relevantGeosFacet?: string;
  materialsAndTechnologyFacet?: string;
  orientationFacet?: string;
  copyrightOrRestrictionsFacet?: string;
};

export type typeSupportedFacet =
  | "relevantDatesFacet"
  | "creatorNameFacet"
  | "relevantGeosFacet"
  | "materialsAndTechnologyFacet"
  | "orientationFacet"
  | "copyrightOrRestrictionsFacet"
  | "assetTypeFacet"
  | "seriesNameFacet"
  | "exhibitionLoanHistoryFacet"
  | "creationDateFacet"
  | "startDateFacet"
  | "endDateFacet"
  | "categoryFacet";

export const supportedFacet: Array<typeSupportedFacet> = [
  "orientationFacet",
  "copyrightOrRestrictionsFacet",
  "relevantDatesFacet",
  "creatorNameFacet",
  "relevantGeosFacet",
  "materialsAndTechnologyFacet",
  "assetTypeFacet",
  "seriesNameFacet",
  "exhibitionLoanHistoryFacet",
  "creationDateFacet",
  "startDateFacet",
  "endDateFacet",
  "categoryFacet",
];

export const facetKeysToLabel = (facetKey: typeSupportedFacet) => {
  if (facetKey === "copyrightOrRestrictionsFacet") {
    return {
      ...mapPlatformCopyrightToIssuer,
      copyright_expired: "Copyright expired",
      copyright_limited: "Copyright limited",
      full_copyright_granted: "Full copyright granted",
      no_contact: "No contact",
      no_copyright: "No copyright",
    };
  }

  if (facetKey === "categoryFacet") {
    return getLocalizationProvider().categories;
  }

  return {};
};

export const formatDate = (date: Date, format: number): string => {
  switch (format) {
    case 0:
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    case 1:
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    case 2:
      return `${date.getFullYear()}-01-01`;
    case 3:
      return `${date.getFullYear()}-12-31`;
    default:
      return "";
  }
};

const facetConfig = {
  relevantDatesFacet: {
    path: "metadata.relevantDates",
  },
  creatorNameFacet: {
    path: "metadata.creatorName",
  },
  relevantGeosFacet: {
    path: "metadata.relevantGeos",
  },
  materialsAndTechnologyFacet: {
    path: "metadata.materialsAndTechnology",
  },
  orientationFacet: {
    path: "metadata.orientation",
  },
  copyrightOrRestrictionsFacet: {
    path: "metadata.copyrightOrRestrictions",
  },
  assetTypeFacet: {
    path: "metadata.assetType",
  },
  seriesNameFacet: {
    path: "metadata.seriesName",
  },
  exhibitionLoanHistoryFacet: {
    path: "metadata.exhibitionLoanHistory",
  },
  creationDateFacet: {
    path: "metadata.creationDate",
  },
  startDateFacet: {
    path: "metadata.creationDate_startDate",
  },
  endDateFacet: {
    path: "metadata.creationDate_endDate",
  },
  categoryFacet: {
    path: "metadata.categories",
  },
};

export const mapFaceKeyToLabel = (facetKey: string, tenantId: string) => {
  const config = facetConfig[facetKey as typeSupportedFacet];
  if (config) {
    return {
      key: facetKey,
      label: getLocalizationProvider(tenantId).facet[facetKey],
      path: config.path,
    };
  }
};

export const getExactFilters = (query: any, tenantId: string) => {
  const filters: any = [];
  const andFilter: any = [];
  supportedFacet.forEach(facetKey => {
    const facetValue = query[facetKey];
    if (facetValue) {
      const facet = mapFaceKeyToLabel(facetKey, tenantId);
      if (facet) {
        const filterValuesArr = facetValue?.split("||") || [];
        if (filterValuesArr.length > 1) {
          const filterGroup: any = [];
          filterValuesArr.forEach((orQuery: string) => {
            filterGroup.push({
              path: facet.path,
              query: orQuery,
            });
          });
          filters.push(filterGroup);
        } else {
          andFilter.push({
            path: facet.path,
            query: facetValue,
          });
        }
      }
    }
  });
  const result: any = {};
  if (filters.length > 0) {
    result.filters = filters;
  }
  if (andFilter.length > 0) {
    result.andFilter = andFilter;
  }
  return result;
};

export const getAppliedFilters = (searchParams: ReadonlyURLSearchParams, tenantId: string) => {
  const exactFilters: any = [];
  supportedFacet.forEach(facetKey => {
    const paramsFacetValue = searchParams?.get(facetKey);
    if (paramsFacetValue) {
      const facetValueArr = paramsFacetValue.split("||");
      if (facetValueArr.length > 1) {
        for (const each of facetValueArr) {
          const facet = mapFaceKeyToLabel(facetKey, tenantId);
          if (facet) {
            const filter = {
              key: facet.key,
              path: facet.path,
              query: each,
            };
            exactFilters.push(filter);
          }
        }
      } else {
        let facetValue = facetValueArr[0];
        const facet = mapFaceKeyToLabel(facetKey, tenantId);
        if (facet) {
          const filter = {
            key: facet.key,
            path: facet.path,
            query: facetValue,
          };
          exactFilters.push(filter);
        }
      }
    }
  });
  return exactFilters.length > 0 ? { filter: exactFilters } : null;
};
