//
import { clsMerge } from "@artifactlabs/shared-react-utils";
import Image, { ImageProps } from "next/image";
import { useState, useRef } from "react";

//
interface GalleryImageProps extends ImageProps {
  title: string | undefined;
  creator: string | undefined;
}

//
const GalleryImage = ({ title, creator, ...rest }: GalleryImageProps) => {
  //
  const [showTitle, setShowTitle] = useState<boolean>(false);
  const ImageRef = useRef<HTMLImageElement | null>(null);

  /* **************************************************** */
  // Event Handlers
  /* **************************************************** */

  const handleMouseEnter = () => {
    if (ImageRef.current && !showTitle) {
      setShowTitle(true);
    }
  };

  const handleMouseOut = () => {
    if (ImageRef.current && showTitle) {
      setShowTitle(false);
    }
  };

  /* ******************************************************************** */
  // Renderer
  /* ******************************************************************** */

  //
  return (
    <div
      className="relative h-full w-full overflow-hidden rounded-[8px]"
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseOut()}
    >
      {showTitle && (
        <div
          className="absolute left-0 top-0 z-[1] flex h-full w-full items-end rounded-[8px] p-[16px]"
          style={{
            background: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <div className="flex w-full flex-col">
            <div className="line-clamp-2 overflow-hidden text-ellipsis text-[14px] font-[600] text-white">
              {title}
            </div>
            <div className="line-clamp-1 overflow-hidden text-ellipsis text-[12px] font-[400] text-white">
              {creator}
            </div>
          </div>
        </div>
      )}
      <Image
        className={clsMerge(
          "rounded-[8px]",
          "transform transition-transform duration-300 ease-in-out",
          showTitle ? "scale-110" : "scale-100",
        )}
        ref={ImageRef}
        {...rest}
      />
    </div>
  );
};

export default GalleryImage;
