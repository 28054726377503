import { useQuery } from "@apollo/client";

import { getCreatorProfilesQuery } from "@/api/queries/search";
import { GetCreatorProfilesQuery, GetCreatorProfilesQueryVariables } from "@/gql/graphql";

const useQueryCreators = ({ skip } = { skip: false }) => {
  const { data, loading, error } = useQuery<
    GetCreatorProfilesQuery,
    GetCreatorProfilesQueryVariables
  >(getCreatorProfilesQuery, {
    fetchPolicy: "cache-first",
    variables: {
      sort: "name-ASC",
    },
    skip,
  });
  return {
    data,
    loading,
    error,
  };
};

export default useQueryCreators;
