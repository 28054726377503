//

export const platform_config = {
  //
  contactEmail: "shopify@globephotos.com",
  //
  allowMobileViewOnDev: false,

  defaultCurrency: "USD",

  // UI toggle - Search Result Filters
  showIssuerFilter: false,
  showCreationDateFilterInCalendar: true,
  showCalendarInYearRange: true,

  showRelatedArtworks: true,

  showFeaturedCreatorsHomeTab: true,

  titleTag: "Artevault | Discover and license our art, culture, and historical imagery and videos",
};
