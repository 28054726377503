import { clsMerge } from "@artifactlabs/shared-react-utils";
import Image from "next/image";
import { ReadonlyURLSearchParams, useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";

import AppLink from "@/components/AppLink";
import ArtworkSearchAutoComplete from "@/components/ArtworkSearchAutocomplete";
import Container from "@/components/Container";
import FeaturedCreators from "@/components/FeaturedCreators/FeaturedCreators";
import GalleryCategoryTab, { TabItem } from "@/components/GalleryCategoryTab";
import { MainLayout } from "@/components/Layout/MainLayout";
import MasonryGallery from "@/components/MasonryGallery";
import { useSearch } from "@/hooks/artworks/useSearch";
import { useCustomTranslation } from "@/hooks/useCustomTranslation";
import useQueryCreators from "@/hooks/useQueryCreators";
import { useWindowResize } from "@/hooks/useWindowResize";
import { NextPageWithLayout } from "@/pages/page";
import tenantAtom from "@/recoil/tenant/atom";
import { getExactFilters } from "@/utils/helpers/filters";
import { getOrgConfigByTenantId } from "@/utils/org";
import { routes } from "@/utils/routes";
import { getTenantConfigs } from "@/utils/tenant";

const CREATORS_TAB_NAME = "featured_creators";

const STORAGE_URL = process.env.NEXT_PUBLIC_S3_PUBLIC_ASSETS_BUCKET_HOST_NAME!;

// too much hard coding at this point, so might as well make them constants
const TENANT_ID_PLATFORM = "platform";
const TENANT_ID_SCMP = "scmp";

const Home: NextPageWithLayout = () => {
  const searchParams = useSearchParams() as ReadonlyURLSearchParams;
  const router = useRouter();

  const [isSearchResultBoxIsOpen, setIsSearchResultBoxIsOpen] = useState(false);
  const { t } = useCustomTranslation("home");
  const [currentTab, setCurrentTab] = useState<string>(searchParams.get("tab") ?? "all");

  const [tenant] = useRecoilState(tenantAtom);
  const config = getOrgConfigByTenantId(tenant.id);
  const tenantConfig = getTenantConfigs(tenant.id ?? "");

  /** Note: Eventually, we should sync the logic for homepage filters and API category filters */
  const currentCategoryFilters = tenantConfig.categories[currentTab] ?? {};
  const exploreParams = new URLSearchParams();
  if (currentTab !== "all") {
    exploreParams.set("categoryFacet", currentTab);
  } else {
    exploreParams.set(
      "categoryFacet",
      Object.keys(tenantConfig.categories)
        .filter(x => x !== "all")
        .join("||"),
    );
  }
  //
  const isMobile = useWindowResize();
  const pageSize = isMobile
    ? config.responsiveHomeAssetsCount.mobile
    : config.responsiveHomeAssetsCount.desktop;

  const creatorsTabOpen = currentTab === CREATORS_TAB_NAME;

  const {
    data: featuredCreators,
    loading: loadingFeaturedCreators,
    error: errorFeaturedCreators,
    // don't fetch if current tab is not featured creators, or if feature flag is disabled
  } = useQueryCreators({ skip: !config.showFeaturedCreatorsHomeTab || !creatorsTabOpen });

  const navItems = useMemo(() => {
    if (!config.showCategories) {
      return [];
    }

    const categories = Object.entries(tenantConfig.categories).map(([key]) => ({
      subject: key,
      text: tenantConfig.translation.categories[key],
      current: currentTab == key,
      handleClick: () => {
        // do nothing when current is already selected
        if (currentTab === key) {
          return;
        }
        router.replace(`${routes.home}?tab=${key}`, undefined, { scroll: false });
        setCurrentTab(key);
      },
    }));

    if (!config.showFeaturedCreatorsHomeTab) {
      return categories;
    }

    return [
      ...categories,
      {
        subject: CREATORS_TAB_NAME,
        text: t("FEATURED_CREATORS"),
        current: currentTab == CREATORS_TAB_NAME,

        handleClick: () => {
          router.replace(`${routes.home}?tab=${CREATORS_TAB_NAME}`, undefined, { scroll: false });
          setCurrentTab(CREATORS_TAB_NAME);
        },
      },
    ];
  }, [currentTab, tenant.id, loadingFeaturedCreators]);

  /** Note: Eventually, we should sync the logic for homepage filters and API category filters */
  const { data, loading, error, refetch } = useSearch({
    keyword: "",
    exactFilters: getExactFilters(currentCategoryFilters, tenant?.id ?? ""),
    options: {
      limit: pageSize,
      randomPool: currentTab,
      isFixedResult: currentTab === "all" || currentTab === "iconicMoments",
    },
    skip: creatorsTabOpen, // skip if current tab is featured creators
  });

  useEffect(() => {
    refetch();
  }, [currentTab, refetch]);

  /* ************************************************************** */
  // Renderers
  /* ************************************************************** */

  //
  const renderTitle = () => {
    switch (tenant.id) {
      case TENANT_ID_SCMP: {
        return (
          <Container
            className={clsMerge("flex items-center justify-center", "mt-[66px] md:mt-[86.98px]")}
          >
            <h1
              className={clsMerge(
                "custom-theme-home-header-title text-center",
                "text-[40px] md:text-[80px]",
                "font-[400] md:font-[400]",
                "leading-[44px] md:leading-[88px]",
              )}
            >
              {t("TITLE_1")}
              <span className="font-[600] italic"> {t("TITLE_2")}</span>
            </h1>
          </Container>
        );
      }
      case TENANT_ID_PLATFORM: {
        return (
          <Container
            className={clsMerge("content-baseline items-center justify-center md:justify-end")}
          >
            <div className="my-[24px] w-full items-center justify-center px-[15px] md:mx-auto md:mb-[40px] md:mt-[80px] md:w-[720px]">
              <h1 className="text-center text-[20px] font-semibold leading-[30px] tracking-wider text-white md:text-[36px] md:leading-[54px]">
                {t("TITLE")}
              </h1>
            </div>
          </Container>
        );
      }
      default: {
        return (
          <Container
            className={clsMerge(
              " flex h-[80px] flex-col content-baseline items-center justify-center bg-tenant-gray-soft md:h-[188px] md:justify-end",
              "custom-theme-container-background",
            )}
          >
            <div className="flex w-full flex-col items-center justify-center">
              <h1 className="custom-theme-home-header-title text-[20px] font-[400px] leading-[24px] tracking-wider md:text-[48px] md:leading-[60px]">
                {t("TITLE")}
              </h1>
            </div>
          </Container>
        );
      }
    }
  };

  //
  const renderSearchBar = () => {
    return (
      <ArtworkSearchAutoComplete
        className={clsMerge(
          tenant.id === TENANT_ID_PLATFORM
            ? "mx-[15px] mb-[24px] w-full md:mb-[80px] md:max-w-[720px]"
            : "w-full",
          isSearchResultBoxIsOpen ? "z-[35]" : "z-[0]",
        )}
        currentState={(isOpen: boolean) => {
          setIsSearchResultBoxIsOpen(isOpen);
        }}
      />
    );
  };

  //
  const renderImageBox = (description: string, src: string) => {
    return (
      <Image alt={description} className="rounded-[8px] object-cover" layout="fill" src={src} />
    );
  };

  //
  const renderGeneralPage = () => {
    const isTenantPlatform = tenant.id === TENANT_ID_PLATFORM;
    return (
      <>
        <Container
          className={clsMerge(
            isTenantPlatform ? "custom-theme-search-banner-background" : "bg-tenant-gray-soft",
            "relative, md:px-100, z-[35]",
          )}
        >
          {/* Page Title */}
          {renderTitle()}

          {/* Search bar */}
          <div
            className={clsMerge(
              "custom-theme-container-background flex flex-col items-center justify-center px-[12px]",
              !isTenantPlatform && "mb-[12px] md:h-[102px]",
            )}
          >
            {renderSearchBar()}
          </div>
        </Container>

        {/* Tab */}
        <GalleryCategoryTab
          keepCentered={navItems.length <= 3} /* keep the items centered if there are 3 or less */
        >
          {navItems.map(({ subject, current, text, handleClick }) => (
            <TabItem current={current} handleClick={handleClick} key={subject}>
              {text}
            </TabItem>
          ))}
        </GalleryCategoryTab>

        {error && (
          <div className="mx-auto flex w-full items-center justify-center bg-white pt-10">
            {t("common.errors.SERVICE_UNAVAILABLE")}
          </div>
        )}

        {/* Content */}
        <Container className="relative px-[15px] lg:px-[40px]">
          {!creatorsTabOpen && (
            <MasonryGallery
              assets={data?.searchArtworks}
              className="mt-4 min-h-screen bg-white"
              loading={loading}
            >
              <div className="flex items-center justify-center pb-[40px] pt-12">
                <AppLink
                  className="rounded-[4px] border-[1px] border-tenant-blue-dark bg-tenant-blue-dark px-4 py-[10px] hover:bg-tenant-blue-darker"
                  href={routes.search(
                    /*page*/ 1,
                    "image",
                    exploreParams,
                    /*withDefaultParams*/ true,
                  )}
                >
                  <div className=" text-sm text-white">Explore</div>
                </AppLink>
              </div>
            </MasonryGallery>
          )}
          <FeaturedCreators
            className="mb-20 mt-4"
            data={featuredCreators?.getCreatorProfiles ?? []}
            hasError={!!errorFeaturedCreators}
            show={creatorsTabOpen}
          />
        </Container>

        {isSearchResultBoxIsOpen && (
          <div
            className={clsMerge(
              "fixed left-0 top-0 z-[31] hidden h-full w-full bg-black bg-opacity-30",
            )}
          />
        )}
      </>
    );
  };

  //
  const renderSCMPPage = () => {
    return (
      <>
        <Container
          className={clsMerge("w-full max-w-[499px] md:max-w-[859px]", "px-[38px] md:px-0")}
        >
          {/* Page Title */}
          {renderTitle()}
        </Container>

        {/* Search bar */}
        <Container
          className={clsMerge(
            "w-full max-w-[499px] md:max-w-[859px]",
            "px-[12px] md:px-[0px]",
            "mt-[51px] md:mt-[48px]",
            "z-[35]",
          )}
        >
          <div className="w-full">{renderSearchBar()}</div>
        </Container>

        {/* Content */}
        <Container
          className={clsMerge(
            "relative",
            // "w-full md:w-[714px] lg:w-[1440px]",
            "mt-[57px] md:mt-[97px]",
            // "px-0 md:px-[28px]",
            "mb-[90px] md:mb-[94px]",
          )}
        >
          {!isMobile && (
            <div className="item-center flex justify-center gap-[20px] overflow-x-hidden">
              <div className="flex items-center">
                <div
                  className={clsMerge(
                    "relative h-[354.78px] w-[243px] overflow-hidden rounded-[8px]",
                  )}
                >
                  {renderImageBox(
                    "homepage image 1",
                    `https://${STORAGE_URL}/T-SCMP/homePageImages/1.jpg`,
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-[16.27px]">
                {/*  */}
                <div className="flex flex-row items-end gap-[20px]">
                  <div
                    className={clsMerge(
                      "relative h-[298.87px] w-[507px] overflow-hidden rounded-[8px]",
                    )}
                  >
                    {renderImageBox(
                      "homepage image 2",
                      `https://${STORAGE_URL}/T-SCMP/homePageImages/2.jpg`,
                    )}
                  </div>

                  <div
                    className={clsMerge(
                      "relative h-[221.61px] w-[331px] overflow-hidden rounded-[8px] ",
                    )}
                  >
                    {renderImageBox(
                      "homepage image 3",
                      `https://${STORAGE_URL}/T-SCMP/homePageImages/3.jpg`,
                    )}
                  </div>
                </div>

                {/*  */}
                <div className="flex flex-row items-start gap-[20px] ">
                  <div
                    className={clsMerge(
                      "relative h-[221.61px] w-[331px] overflow-hidden rounded-[8px]",
                    )}
                  >
                    {renderImageBox(
                      "homepage image 5",
                      `https://${STORAGE_URL}/T-SCMP/homePageImages/5.jpg`,
                    )}
                  </div>

                  <div
                    className={clsMerge(
                      "relative h-[298.87px] w-[507px] overflow-hidden rounded-[8px]",
                    )}
                  >
                    {renderImageBox(
                      "homepage image 6",
                      `https://${STORAGE_URL}/T-SCMP/homePageImages/6.jpg`,
                    )}
                  </div>
                </div>
              </div>

              <div className="flex items-center">
                <div
                  className={clsMerge(
                    "relative h-[354.78px] w-[243px] overflow-hidden rounded-[8px]",
                  )}
                >
                  {renderImageBox(
                    "homepage image 4",
                    `https://${STORAGE_URL}/T-SCMP/homePageImages/4.jpg`,
                  )}
                </div>
              </div>
            </div>
          )}

          {isMobile && (
            <div className="flex w-full items-center justify-center overflow-x-hidden">
              <div className="item-center flex w-[424.28px] flex-col justify-center gap-[3.76px]">
                {/* First Row */}
                <div className="relative flex items-end gap-[4.04px]">
                  <div
                    className={clsMerge(
                      "relative h-[75.04px] w-[75.04px] overflow-hidden rounded-[8px]",
                    )}
                  >
                    {renderImageBox(
                      "homepage image 1",
                      `https://${STORAGE_URL}/T-SCMP/homePageImages/1.jpg`,
                    )}
                  </div>

                  <div
                    className={clsMerge(
                      "relative h-[142px] w-[157px] overflow-hidden rounded-[8px]",
                    )}
                  >
                    {renderImageBox(
                      "homepage image 2",
                      `https://${STORAGE_URL}/T-SCMP/homePageImages/2.jpg`,
                    )}
                  </div>

                  <div
                    className={clsMerge(
                      "relative h-[85.24px] w-[127.24px] overflow-hidden rounded-[8px]",
                    )}
                  >
                    {renderImageBox(
                      "homepage image 3",
                      `https://${STORAGE_URL}/T-SCMP/homePageImages/3.jpg`,
                    )}
                  </div>
                  <div className="h-[0px] w-[50px] bg-transparent"></div>
                </div>

                {/* Second Row */}
                <div className="flex items-start gap-[4.04px]">
                  <div className="h-[0px] w-[50px] bg-transparent"></div>
                  <div
                    className={clsMerge(
                      "relative h-[85.24px] w-[127.24px] overflow-hidden rounded-[8px]",
                    )}
                  >
                    {renderImageBox(
                      "homepage image 5",
                      `https://${STORAGE_URL}/T-SCMP/homePageImages/5.jpg`,
                    )}
                  </div>
                  <div
                    className={clsMerge(
                      "relative h-[127px] w-[157px] overflow-hidden rounded-[8px]",
                    )}
                  >
                    {renderImageBox(
                      "homepage image 6",
                      `https://${STORAGE_URL}/T-SCMP/homePageImages/6.jpg`,
                    )}
                  </div>

                  <div
                    className={clsMerge(
                      "relative h-[75.04px] w-[75.04px] overflow-hidden rounded-[8px]",
                    )}
                  >
                    {renderImageBox(
                      "homepage image 4",
                      `https://${STORAGE_URL}/T-SCMP/homePageImages/4.jpg`,
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Container>

        {isSearchResultBoxIsOpen && (
          <div className="fixed left-0 top-0 z-[31] h-full w-full bg-black bg-opacity-30 md:hidden" />
        )}
      </>
    );
  };

  //
  return (
    <div
      className={clsMerge(
        "flex flex-grow flex-col overflow-x-hidden bg-white",
        isSearchResultBoxIsOpen ? "overflow-y-hidden" : "overflow-y-auto",
      )}
    >
      {/* {`Desktop: ${isDesktop} Tablet: ${isTablet} Mobile: ${isMobile}`} */}
      {(() => {
        switch (tenant.id) {
          case TENANT_ID_SCMP:
            return renderSCMPPage();
          default:
            return renderGeneralPage();
        }
      })()}
    </div>
  );
};

Home.getLayout = (page: React.ReactNode) => {
  //
  const {
    props: { reskinningConfig },
  } = page as { props: any };

  // console.log("DEBUG: home page headers", headers);
  return (
    <MainLayout reskinningConfig={reskinningConfig} withLogo={true} showSubscribeNewsletter>
      {page}
    </MainLayout>
  );
};

export default Home;
