
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { NextJsTracking, NextJsNoScriptTracking } from "@artifactlabs/shared-react-utils";
import { AnimatePresence } from "framer-motion";
import { NextComponentType } from "next";
import { AppProps } from "next/app";
import { AppContext, AppInitialProps } from "next/app";
import { useParams } from "next/navigation";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot } from "recoil";
import { ToastContainer } from "react-toastify";
import { type NextPageWithLayout } from "./page";
import AppAuthenticationProvider from "@/components/Authentication/AppAuthenticationProvider";
import ApolloContextProvider from "@/components/Context/ApolloContextProvider";
import LoadFontsByTenant from "@/components/LoadFontsByTenant";
import TenantProvider from "@/components/TenantProvider/TenantProvider";
import "@/styles/components/react-datepicker.css";
import "@/styles/components/react-tabs.css";
import "@/styles/globals.css";
import "@/styles/tenant/map.css";
import "@/styles/tenant/hkphil.css";
import "@/styles/tenant/ngs.css";
import "@/styles/tenant/platform.css";
import "@/styles/tenant/scmp.css";
import "@/styles/tenant/usn.css";
import "@/styles/tenant/snm.css";
const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;
interface AppPropsWithLayout extends AppProps {
    Component: NextPageWithLayout;
}
const MyApp: NextComponentType<AppContext, AppInitialProps, AppPropsWithLayout> = ({ Component, pageProps: { ...pageProps }, }: AppPropsWithLayout) => {
    const params = useParams();
    const subdomain = (params?.subdomain as string) ?? "";
    const getLayout = Component.getLayout || (page => page);
    return (<>
      <LoadFontsByTenant tenantId={subdomain}/>
      {GA_MEASUREMENT_ID && (<>
          <NextJsNoScriptTracking gaMeasurementId={GA_MEASUREMENT_ID}/>
          <NextJsTracking gaMeasurementId={GA_MEASUREMENT_ID}/>
        </>)}
      <RecoilRoot>
        <TenantProvider tenantId={subdomain}>
          <AppAuthenticationProvider tenantId={subdomain}>
            <ApolloContextProvider tenantId={subdomain}>
              <AnimatePresence mode="wait">
                {/* <ServiceUnavailableBlocker> */}
                <div className={`tenant-${subdomain}`}>
                  {getLayout(<Component {...pageProps}/>)}
                </div>
                {/* </ServiceUnavailableBlocker> */}
              </AnimatePresence>
            </ApolloContextProvider>
          </AppAuthenticationProvider>
        </TenantProvider>
        <ToastContainer />
      </RecoilRoot>
    </>);
};
const __Next_Translate__Page__194962210df__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__194962210df__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  