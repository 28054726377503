import { clsMerge } from "@artifactlabs/shared-react-utils";
import { motion } from "framer-motion";
import { HTMLAttributes } from "react";

type TabItemProps = HTMLAttributes<HTMLButtonElement> & {
  current: boolean;
  handleClick: () => void;
  children: React.ReactNode;
};

const TabItem = ({ current, handleClick, className, children }: TabItemProps) => {
  return (
    <button
      className={clsMerge(
        "relative flex-shrink-0 py-3 text-base font-semibold tracking-wider text-black md:min-w-[56px]",
        className,
      )}
      onClick={handleClick}
    >
      {children}
      {current && (
        <motion.div
          className="absolute bottom-[0px] h-[2px] w-full bg-black"
          layoutId="grid.header.underline"
        />
      )}
    </button>
  );
};

export default TabItem;
