//
export const default_config = {
  //
  contactEmail: "",
  //
  allowMobileViewOnDev: true,
  //
  licenseLink: `https://${process.env.NEXT_PUBLIC_S3_PUBLIC_ASSETS_BUCKET_HOST_NAME!}/documents/content-licensing/Artevault_Content+Licensing+Agreement.pdf`,

  termsAndConditionsLink: `https://${process.env.NEXT_PUBLIC_S3_PUBLIC_ASSETS_BUCKET_HOST_NAME!}/documents/terms-and-conditions/Artevault_Platform+Terms+and+Conditions.pdf`,

  hideContractInfo: true,

  defaultCurrency: "USD",

  showRelatedArtworks: false,

  /** [DEV-2275] These are searchable by default on all Artevaults except SCMP */
  creatorNameFacetShowSearchbarFilter: true,
  relevantGeosFacetShowSearchbarFilter: true,
  materialsAndTechnologyFacetShowSearchbarFilter: true,
  assetTypeFacetShowSearchbarFilter: true,
  seriesNameFacetShowSearchbarFilter: true,
  /** */

  // [DEV-2060] main flag
  showCategories: true,

  showFeaturedCreatorsHomeTab: false,

  // [DEV-2060] only applicable when showCategories is true
  responsiveHomeAssetsCount: {
    mobile: 10,
    desktop: 18,
  },

  titleTag: "",
};
