import Image from "next/image";
import { FunctionComponent } from "react";

import AppLink from "@/components/AppLink";

type ImageItemProps = {
  alt: string;
  imageSrc: string;
  navigateTo: string;
};

const ImageItem: FunctionComponent<ImageItemProps> = ({ alt, imageSrc, navigateTo }) => {
  return (
    <div className="w-full">
      <AppLink href={navigateTo}>
        <Image
          alt={alt}
          className="rounded-t-[7px]"
          height={300}
          quality={1}
          sizes="100vw"
          src={imageSrc}
          width={300}
          unoptimized
        />
      </AppLink>
    </div>
  );
};

export default ImageItem;
