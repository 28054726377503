import { clsMerge } from "@artifactlabs/shared-react-utils";
import { FunctionComponent } from "react";

import FeaturedCreator from "./FeaturedCreator";

import { GetCreatorProfilesQuery } from "@/gql/graphql";
import { useCustomTranslation } from "@/hooks/useCustomTranslation";

type FeaturedCreatorsProps = {
  className?: string;
  data: GetCreatorProfilesQuery["getCreatorProfiles"];
  hasError: boolean;
  show: boolean;
};

const STORAGE_URL = process.env.NEXT_PUBLIC_S3_PUBLIC_ASSETS_BUCKET_HOST_NAME;

const artistProfileImage = (artistName: string) =>
  `https://${STORAGE_URL}/artists/${artistName}.png`;

const FeaturedCreators: FunctionComponent<FeaturedCreatorsProps> = ({
  className,
  data,
  hasError,
  show,
}) => {
  const { t } = useCustomTranslation("home");

  if (!show) {
    return null;
  }

  if (hasError) {
    return (
      <div className="mx-auto flex w-full items-center justify-center bg-white pt-10">
        {t("common.errors.SERVICE_UNAVAILABLE")}
      </div>
    );
  }

  return (
    <section
      className={clsMerge(
        "grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5",
        className,
      )}
    >
      {data?.map(
        each =>
          each && (
            <FeaturedCreator
              artworkCount={each.assetsCount ?? 0}
              imageUrl={artistProfileImage(each.creatorName)}
              key={each.creatorName}
              name={each.creatorName}
            />
          ),
      )}
    </section>
  );
};

export default FeaturedCreators;
