
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Home from "@/components/Pages/Home";
async function getServerSideProps(context: {
    req: any;
    locale: string;
}) {
    const { req, locale = "en" } = context;
    const headers = req.headers; // This is an object containing all headers
    // Pass headers to the page as props
    return {
        props: { headers, locale },
    };
}
export default Home;

    async function __Next_Translate__getServerSideProps__194962210ed__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[subdomain]/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__194962210ed__ as getServerSideProps }
  